<template>
	<section class="profile" ref="scroll-into-view">
		<template v-if="$root.rwd < 2">
			<lottie :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
		</template>
		<div class="max-width-1000">
			<quote-title label="會員資料" />
			<br>
			<br>
			<member-form v-if="editable" ref="ruleForm" />
			<member-form-readonly v-else />
			<div class="center">
				<el-button @click="handleClick()" type="primary">{{editable ? "儲存" : "修改"}}</el-button>
				<template v-if="editable">
					<el-button @click="editable = false" type="info">取消</el-button>
				</template>
			</div>
			<br>
			<template v-if="_auth">
				<div v-if="!_auth.roles.includes('member')" class="center">
					<router-link to="/join/member">
						<el-button type="theme" round>成為{{"\u3000"}}金粉會員</el-button>
					</router-link>
				</div>
				<div v-else-if="!_auth.roles.includes('donor')" class="center">
					<router-link to="/join/donor">
						<el-button type="theme" round>成為{{"\u3000"}}椅仔會員</el-button>
					</router-link>
				</div>
			</template>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	components: {
		MemberForm: () => import("@/components/profile/member-form"),
		MemberFormReadonly: () => import("@/components/profile/member-form-readonly"),
	},
	data() {
		return {
			editable: false,
		}
	},
	computed: {
		...mapState("auth", ["_auth"])
	},
	watch: {
		editable(val) {
			if (this.$refs["scroll-into-view"]) {
				this.$refs["scroll-into-view"].scrollIntoView({ behavior: "smooth" });
			}
		}
	},
	methods: {
		...mapActions("auth", ["_updateProfile", "_applyUpgrade", "_cancelApplyUpgrade"]),
		async handleClick() {
			try {
				if (this.editable) {
					await this._updateProfile(
						await this.$refs["ruleForm"].validate()
					);
					this.$message.success("更新成功");
				}
				this.editable = !this.editable;
			} catch (error) {
				this.$message.error(error);
			}
		}
	},
	created() {
		const { apply_upgrade } = this._auth || new Object;
		if (apply_upgrade && apply_upgrade.status == 2) {
			this.$msgbox({
				type: "info",
				title: "您的椅仔會員已被取消",
				message: `原因為：<br><br><pre>${apply_upgrade.message}</pre><p style="text-align:right;">是否再次申請？</p>`,
				dangerouslyUseHTMLString: true,
				showCancelButton: true,
				cancelButtonText: "否",
				confirmButtonText: "是",
			}).then(async () => {
				const { message } = await this._applyUpgrade(this._auth);
				this.$alert(message, { type: "success" });
			}).catch(() => {
				this.$confirm("", "確認取消申請？", { type: "warning" })
					.then(async () => {
						this.$alert(await this._cancelApplyUpgrade(), { type: "success" });
					})
					.catch(() => this.$message.info("已取消所有動作"))
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.profile {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../assets/background/brush2.png"),
		url("../assets/background/dot1.png"),
		url("../assets/background/dot2.png");
	background-position: center 70%, center 80%, center 80%;

	.lottie {
		position: absolute;
		width: 25%;
		max-width: 250px;
		bottom: 0;
		left: 5%;
		transform: translateY(20%);
	}
}
</style>